import { createRouter, createWebHistory } from "vue-router";
import { httpPostRefreshToken, httpPost } from "@/api/httpService";
import VueCookies from "vue-cookies";
import { ElMessage } from "element-plus";
import Home from "../views/Home.vue";
// const MODULE = {
//   HOME: "0",
//   TEXT_LIBRARY: "1",
//   ANALYSIS: "2",
//   ANALYSIS_CONFIG: "3",
//   TEMPLATE_REPOSITORY: "4",
//   RECYCLE_BIN: "5",
// };

const routes = [
  // {
  //   path: "/",
  //   name: "Home",
  //   component: Home,
  // },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
  {
    path: "/",
    name: "Index",
    component: () =>
      import(/* webpackChunkName: "Index" */ "@/views/index/index.vue"),
  },
  {
    path: "/case",
    name: "Case",
    component: () =>
      import(/* webpackChunkName: "Case" */ "@/views/index/case.vue"),
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: () =>
      import(/* webpackChunkName: "Pricing" */ "@/views/index/pricing.vue"),
  },
  {
    path: "/pay",
    name: "Pay",
    component: () =>
      import(/* webpackChunkName: "Pay" */ "@/views/index/pay.vue"),
  },
  // {
  //   path: "/privacy",
  //   name: "Privacy",
  //   component: () =>
  //     import(/* webpackChunkName: Privacy */ "@/views/index/privacy"),
  // },
  {
    path: "/",
    redirect: "/project",
    component: () =>
      import(
        /*webpackChunkName: "Layout" */ "@/components/layout/BaseLayout.vue"
      ),
    children: [
      // 首页
      {
        path: "/project/:folderId",
        name: "ProjectIndexWithFolder",
        component: () =>
          import(
            /*webpackChunkName: "Project" */ "@/views/project/StatisticLibraryIndex.vue"
          ),
      },
      {
        path: "/project",
        name: "ProjectIndex",
        component: () =>
          import(
            /*webpackChunkName: "Project" */ "@/views/project/StatisticLibraryIndex.vue"
          ),
      },

      //回收站
      {
        path: "/project/recycle-bin",
        name: "RecycleBin",
        component: () =>
          import(
            /*webpackChunkName: "Project" */ "@/views/project/RecycleBinIndex.vue"
          ),
      },
      //变量管理
      {
        //path: "/variable-management/:dataSetId",
        path: "/variable-management/:libraryId",
        name: "VariableManagement",
        component: () =>
          import(
            /*webpackChunkName: "Project" */ "@/views/project/VariableManagementIndex.vue"
          ),
      },

      // 数据管理模块
      {
        path: "/data-record/:libraryId/",
        name: "DataRecord",
        component: () =>
          import(
            /*webpackChunkName: "Project" */ "@/views/project/DataRecord.vue"
          ),
      },
      // 分析模块
      {
        path: "/analysis/",
        redirect: "/analysis/index/:libraryId",
        component: () =>
          import(
            /*webpackChunkName: "Layout" */ "@/views/analysis/AnalysisLayout.vue"
          ),
        meta: {
          ifHideFooter: true,
          ifHidePageHeader: true,
          ifHidePadding: true,
        },
        children: [
          {
            path: "/analysis/index/:libraryId",
            name: "AnalysisIndex",
            component: () =>
              import(
                /*webpackChunkName: "Project" */ "@/views/analysis/components/AnalysisList.vue"
              ),
            meta: {
              ifHideFooter: true,
              ifHidePageHeader: true,
              ifHidePadding: true,
            },
          },
        ],
      },
      {
        path: "/user",
        name: "User",
        component: () =>
          import(/* webpackChunkName: "User" */ "../views/UserRight.vue"),
      },
    ],
  },

  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
];

const router = createRouter({
  // history: createWebHistory("/newversion/"),
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

//路由守卫
const whiteList = [
  "/",
  "/index",
  "/case",
  "/pricing",
  "/pay",
  "/login",
  "/register",
  "/findPassword",
  "/privacy",
];
router.beforeEach(async (to, from, next) => {
  console.log("to", to);
  // 刷新Token
  if (!VueCookies.isKey("tokenWeb") && VueCookies.isKey("tokenWebRefresh")) {
    await httpPostRefreshToken(
      "/oauth/token",
      VueCookies.get("tokenWebRefresh")
    );
  }

  if (
    VueCookies.isKey("tokenWeb") &&
    VueCookies.isKey("userInfo") &&
    VueCookies.isKey("tokenWebRefresh")
  ) {
    if (whiteList.indexOf(to.path) == -1 && whiteList.indexOf(to.name) == -1) {
      httpPost("/user/v1/permission", null).then((res) => {
        if (res && res.code == 0) {
          let userInfo = VueCookies.get("userInfo");

          userInfo.role = res.data.role;
          VueCookies.set("userInfo", JSON.stringify(userInfo));
        }
      });
    }
    // 获取用户权限

    if (to.path === "/login") {
      next("/project");
    }

    console.log("next", to);
    next();
  } else {
    if (
      whiteList.indexOf(to.path) !== -1 ||
      whiteList.indexOf(to.name) !== -1
    ) {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      ElMessage({
        type: "info",
        message: "登录信息已失效,请重新登录",
      });
      next("/login");
    }
  }
});
export default router;
