import { ElMessageBox } from "element-plus";
//封装一个确认信息的弹框
const confirm = (message, okfun) => {
  return ElMessageBox.confirm(message, "提示", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      okfun();
    })
    .catch(() => {});
};

export default confirm;
