import { ElMessage } from "element-plus";
const Message = {
  error: (msg) => {
    ElMessage({
      message: msg,
      type: "error",
      duration: 2000,
      customClass: "message-prompt-box",
    });
  },
  success: (msg) => {
    ElMessage({
      message: msg,
      type: "success",
      duration: 2000,
      customClass: "message-prompt-box",
    });
  },
  // warning: (msg) => {
  //   ElMessage({
  //     message: msg,
  //     type: "warning",
  //     duration: 2000,
  //     customClass: "message-prompt-box",
  //   });
  // },
  warning: (...args) => {
    // console.log(args[0]);
    // console.log(typeof args[0]);
    let defaultMessage = {
      message: null,
      type: "warning",
      duration: 2000,
      customClass: "message-prompt-box",
    };
    if (typeof args[0] == "string") {
      ElMessage({ ...defaultMessage, message: args[0] });
    } else {
      // console.log(...args);
      ElMessage({ ...defaultMessage, ...args[0] });
    }
  },
};

export default Message;
