import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

const app = createApp(App);

// 引入element-plus
import ElementPlus from "element-plus";
// import "element-plus/dist/index.css";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
app.use(ElementPlus, {
  locale: zhCn,
});

import * as ElementPlusIconsVue from "@element-plus/icons-vue";
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
// import "./assets/styles/element/index.scss";
// import "element-plus/theme-chalk/display.css";
// import "element-plus/dist/index.css";

// 引入样式文件
import "./assets/styles/index.scss";
// import "./assets/styles/reset.scss";
// import "./assets/styles/media.scss";
// import "./assets/styles/base.scss";
//引入阿里巴巴字体图标库
// import "./assets/styles/iconfont.scss"; //此文件只在开发环境下引用
// import "./assets/style/font/iconfont.scss";//此文件在线上环境下引用，

//自定义指令
import defineDirective from "@/utils/directives";

//引入全局方法
import message from "./utils/message";
import confirm from "./utils/confirm";
app.config.globalProperties.message = message;
app.config.globalProperties.confirm = confirm;

app.use(store).use(router).mount("#app");
